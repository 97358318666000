import { GenericApiResponse } from '@type/general';
import restApi from '@services/api';

import {
  TransactionsListPayload,
  TransactionsListResponse
} from '../transactions-section/types';
import { tableHeaderMapper } from '../transactions-section/constants';
import {
  ConfirmPaymentRequestType,
  LiveDealDetailsPayload,
  LiveDealDetailsResponse,
  TransactionHistoryPayload,
  TransactionHistoryResponse
} from './types';

const GetTransactionApi = restApi.injectEndpoints({
  endpoints: builder => ({
    // API for fetching the list of pending payment attributions
    getAttributionPendingList: builder.query<
      TransactionsListResponse,
      TransactionsListPayload
    >({
      query: ({
        limit,
        offset,
        search,
        sort,
        order,
        hasVirtualAccountBalance
      }) => ({
        url: `${
          process.env.REACT_APP_BASE_API_VERSION
        }/support/users/pending-payment-attribution?limit=${limit}&offset=${offset}&sort=${
          tableHeaderMapper[sort as keyof typeof tableHeaderMapper]
        }&order=${order}${
          search ? `&search=${encodeURIComponent(search)}` : ''
        }&has-virtual-account-balance=${hasVirtualAccountBalance}`,
        method: 'GET'
      })
    }),

    // API for fetching the transaction payment history
    getTransactionHistory: builder.query<
      TransactionHistoryResponse,
      TransactionHistoryPayload
    >({
      query: ({ userId }) => ({
        url: `${process.env.REACT_APP_BASE_API_VERSION}/support/user/${userId}/payments/pending-attribution`,
        method: 'GET'
      }),
      transformResponse: (response: TransactionHistoryResponse) => response
    }),

    // API for fetching live deals details
    getLiveDealsDetails: builder.query<
      LiveDealDetailsResponse,
      LiveDealDetailsPayload
    >({
      query: ({ userId, deal_ids }) => ({
        url: `${
          process.env.REACT_APP_BASE_API_VERSION
        }/support/user/${userId}/owndeals?deal_ids=${deal_ids.join(',')}`,
        method: 'GET'
      }),
      transformResponse: (response: LiveDealDetailsResponse) => response
    }),

    // API for confirming payment attribution
    confirmPayment: builder.mutation<
      GenericApiResponse<string>,
      ConfirmPaymentRequestType
    >({
      query: payload => ({
        url: `${process.env.REACT_APP_BASE_API_VERSION}/support/utils/payment/manual/confirm`,
        method: 'POST',
        body: payload
      }),
      transformResponse: (response: GenericApiResponse<string>) => response
    })
  })
});

export const {
  useLazyGetAttributionPendingListQuery,
  useGetAttributionPendingListQuery,
  useLazyGetTransactionHistoryQuery,
  useLazyGetLiveDealsDetailsQuery,
  useConfirmPaymentMutation
} = GetTransactionApi;
