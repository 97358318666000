import { ReducerStateType } from '@modules/transaction/transactions-section/types';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  offset: 0,
  limit: 25,
  search: '',
  order: 'desc',
  sortBy: 'name',
  clearPage: true,
  totalCount: 0
} as ReducerStateType;

export const transactionsAttributedListSlice = createSlice({
  name: 'transactionsAttributedListing',
  initialState,
  reducers: {
    resetTransactionAttributedListingValues: state => ({
      ...state,
      offset: 0,
      limit: 25,
      search: '',
      order: 'desc',
      sortBy: 'name',
      totalCount: 0
    }),
    updateTransactionAttributedListingValues: (state, { payload }) => ({
      ...state,
      ...payload
    })
  }
});

export const {
  resetTransactionAttributedListingValues,
  updateTransactionAttributedListingValues
} = transactionsAttributedListSlice.actions;

export default transactionsAttributedListSlice.reducer;
