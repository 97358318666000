import { ReducerStateType } from '@modules/transaction/transactions-section/types';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  offset: 0,
  limit: 25,
  search: '',
  order: 'desc',
  sortBy: 'name',
  clearPage: true,
  totalCount: 0
} as ReducerStateType;

export const transactionsListSlice = createSlice({
  name: 'transactionsListing',
  initialState,
  reducers: {
    resetTransactionListingValues: state => ({
      ...state,
      offset: 0,
      limit: 25,
      search: '',
      order: 'desc',
      sortBy: 'name',
      totalCount: 0
    }),
    updateTransactionListingValues: (state, { payload }) => ({
      ...state,
      ...payload
    })
  }
});

export const { resetTransactionListingValues, updateTransactionListingValues } =
  transactionsListSlice.actions;

export default transactionsListSlice.reducer;
