import React, { Suspense, lazy, useEffect, useMemo } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { NavBar, PageLoader, TopBar } from '@components';
import { updateUserListingClearPage } from '@modules/users/user-listing/reducer';
import { useAppDispatch } from '@store/store';
import { translate } from '@utils/locale';
import { useGetUnverifiedKycDocsListQuery } from '@modules/kyc-approval/verify-user-listing/api';
import {
  updateScreeningCount,
  updateTransactionCount,
  updateUnverifiedCount
} from '@reducers/unverifiedDocsCount';
import { useGetScreeningUsersListQuery } from '@modules/screening/screening-list/api';
import { useGetUserProfileQuery } from '@modules/auth/login/api';
import useDownloadAnalyticsReport from '@hooks/useDownloadAnalyticsReport';
import useDownloadSubscribedUsersReport from '@hooks/UseDownloadSubscribedUsersReport';
import useDownloadWishlistedUsersReport from '@hooks/UseDownloadWishlistedUsersReport';
import useDownloadAifMasterReport from '@hooks/useDownloadAifMasterReport';
import OverallSubscriptionStatus from '@modules/opportunities/create-early-access/container/overall-subscription-status/OverallSubscriptionStatus';
import OverallWishlistedUsers from '@modules/opportunities/create-wishlist/container/overall-wishlisted-users/OverallWishlistedUsers';
import useBreadCrumbs from '@hooks/useBreadCrumbs';

import { ClearPageReducerFunctionType } from './types';
import RoutesPath from './RoutesPath';
import { useGetAttributionPendingListQuery } from '@modules/transaction/transactions-attribution-pending-list/api';

const DealsListPage = lazy(() => import('@modules/deals/deals-list'));
const CreateDealPage = lazy(() => import('@modules/deals/create-deal'));
const CreateSyndicatePage = lazy(
  () => import('@modules/syndicate/create-syndicate')
);
const InviteDealPage = lazy(() => import('@modules/deals/deal-invite'));
const InviteSyndicatePage = lazy(
  () => import('@modules/syndicate/syndicate-invite')
);
const SyndicateListPage = lazy(
  () => import('@modules/syndicate/syndicate-list')
);
const CompanyListPage = lazy(() => import('@modules/companies/company-list'));
const CreateCompany = lazy(() => import('@modules/companies/create-company'));
const UserListPage = lazy(() => import('@modules/users/user-listing'));
const AppInviteLogPage = lazy(() => import('@modules/users/app-invite-log'));
const UserDetails = lazy(() => import('@modules/users/user-details'));
const DealAnalytics = lazy(() => import('@modules/deals/deal-analytics'));
const CreateWishlist = lazy(
  () => import('@modules/opportunities/create-wishlist')
);
const CreateEarlyAccess = lazy(
  () => import('@modules/opportunities/create-early-access')
);
const KycVerification = lazy(
  () => import('@modules/kyc-approval/verify-user-listing')
);
const Opportunities = lazy(
  () => import('@modules/opportunities/opportunities-list')
);
const ScreeningList = lazy(() => import('@modules/screening/screening-list'));
const TransactionList = lazy(
  () => import('@modules/transaction/transactions-section')
);

/*
  add the clearPage function configs here
  paths: Array of url path that need to be whitelisted
  reducerFunction: setClear page function of listing page
 */
const clearPageReducerFunctions: ClearPageReducerFunctionType[] = [
  {
    paths: ['/users/details'],
    reducerFunction: updateUserListingClearPage
  }
];

const PrivateLayout = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const { getBreadCrumKeysFromPath, getBreadData } = useBreadCrumbs();

  useDownloadAnalyticsReport();
  useDownloadSubscribedUsersReport();
  useDownloadWishlistedUsersReport();
  useDownloadAifMasterReport();

  const breadCrumbsData = useMemo(
    () =>
      getBreadData(getBreadCrumKeysFromPath(location.pathname), 0).reverse(),
    [location.pathname]
  );

  const navBarItems = [
    { cta: translate('navBar.deals'), path: RoutesPath.DEALS },
    { cta: translate('navBar.opportunities'), path: RoutesPath.OPPORTUNITIES },
    { cta: translate('navBar.companies'), path: RoutesPath.COMPANY },
    { cta: translate('navBar.syndicate'), path: RoutesPath.SYNDICATE },
    { cta: translate('navBar.users'), path: RoutesPath.USER },
    { cta: translate('navBar.screening'), path: RoutesPath.SCREENING },
    { cta: translate('navBar.kycVerification'), path: RoutesPath.VERIFY_USER },
    { cta: translate('navBar.transactions'), path: RoutesPath.TRANSACTIONS }
  ];

  useEffect(() => {
    clearPageReducerFunctions.forEach(item => {
      // checks whether the current url is whitelisted or not
      const pathChecks = item.paths.some(path => {
        return location.pathname.includes(path);
      });
      // should not clear pageNo. incase of edit-page, details-page
      if (!pathChecks) dispatch(item.reducerFunction(true));
    });
  }, [location.pathname]);

  const { data } = useGetUnverifiedKycDocsListQuery({
    search: '',
    offset: 0,
    limit: 1,
    order: 'desc',
    sort: 'submittedOn'
  });

  const { data: screeningData } = useGetScreeningUsersListQuery({
    search: '',
    offset: 0,
    limit: 1,
    order: 'desc',
    sort: 'profiling_status_last_updated',
    status: ['submitted_for_screening', 'processing_failed']
  });

  const { data: transactionData } = useGetAttributionPendingListQuery({
    search: '',
    offset: 0,
    limit: 1,
    order: 'desc',
    sort: 'name'
  });

  const { data: userProfileData } = useGetUserProfileQuery();

  useEffect(() => {
    dispatch(updateUnverifiedCount(data?.total_count || 0));
  }, [data]);

  useEffect(() => {
    dispatch(updateScreeningCount(screeningData?.result.total_count || 0));
  }, [screeningData]);

  useEffect(() => {
    dispatch(updateTransactionCount(transactionData?.result.total_count || 0));
  }, [transactionData]);

  return (
    <div>
      <TopBar breadcrumbsData={breadCrumbsData} />
      <div className="flex flex-row w-full">
        <NavBar
          navBarItems={navBarItems}
          userProfile={userProfileData?.result}
        />
        <div className="relative top-16 left-60 w-[calc(100%-15rem)]">
          <Suspense fallback={<PageLoader />}>
            <Routes>
              <Route path={RoutesPath.DEALS} element={<DealsListPage />} />
              <Route
                path={`${RoutesPath.CREATE_DEAL}/:companyId`}
                element={<CreateDealPage />}
              />
              <Route
                path={`${RoutesPath.CREATE_DEAL}/:cloneId`}
                element={<CreateDealPage />}
              />
              <Route
                path={`${RoutesPath.EDIT_DEAL}/:dealId/:companyId`}
                element={<CreateDealPage />}
              />
              <Route
                path={RoutesPath.DEAL_INVITE}
                element={<InviteDealPage />}
              />
              <Route path={RoutesPath.COMPANY} element={<CompanyListPage />} />
              <Route
                path={`${RoutesPath.CREATE_COMPANY}/:companyId`}
                element={<CreateCompany />}
              />
              <Route
                path={RoutesPath.SYNDICATE}
                element={<SyndicateListPage />}
              />
              <Route
                path={RoutesPath.CREATE_SYNDICATE}
                element={<CreateSyndicatePage />}
              />
              <Route
                path={`${RoutesPath.EDIT_SYNDICATE}/:syndicateId`}
                element={<CreateSyndicatePage />}
              />
              <Route
                path={`${RoutesPath.CREATE_SYNDICATE}/:cloneId`}
                element={<CreateSyndicatePage />}
              />
              <Route
                path={RoutesPath.SYNDICATE_INVITE}
                element={<InviteSyndicatePage />}
              />
              <Route
                path={RoutesPath.DEFAULT}
                element={<Navigate replace to={RoutesPath.DEALS} />}
              />
              <Route
                path={RoutesPath.ALL}
                element={<Navigate replace to={RoutesPath.DEALS} />}
              />
              <Route path={RoutesPath.USER} element={<UserListPage />} />
              <Route
                path={`${RoutesPath.VIEW_USER}/:userId`}
                element={<UserDetails isEdit={true} />}
              />
              <Route
                path={RoutesPath.VERIFY_USER}
                element={<KycVerification />}
              />
              <Route
                path={`${RoutesPath.DEAL_ANALYTICS}/:dealId/:syndicateId`}
                element={<DealAnalytics />}
              />
              <Route
                path={RoutesPath.APP_INVITE}
                element={<AppInviteLogPage />}
              />
              <Route
                path={RoutesPath.OPPORTUNITIES}
                element={<Opportunities />}
              />
              <Route
                path={`${RoutesPath.SCREENING}`}
                element={<ScreeningList />}
              />
              <Route
                path={`${RoutesPath.CREATE_WISHLIST}/:companyId`}
                element={<CreateWishlist isViewMode={false} />}
              />
              <Route
                path={`${RoutesPath.EDIT_WISHLIST}/:dealId/:companyId`}
                element={<CreateWishlist isViewMode={false} />}
              />
              <Route
                path={`${RoutesPath.CREATE_EARLY_ACCESS}/:companyId`}
                element={<CreateEarlyAccess isViewMode={false} />}
              />
              <Route
                path={`${RoutesPath.EDIT_EARLY_ACCESS}/:dealId/:companyId`}
                element={<CreateEarlyAccess isViewMode={false} />}
              />
              <Route
                path={`${RoutesPath.VIEW_EARLY_ACCESS}/:dealId/:companyId`}
                element={<CreateEarlyAccess isViewMode={true} />}
              />
              <Route
                path={`${RoutesPath.VIEW_WISHLIST}/:dealId/:companyId`}
                element={<CreateWishlist isViewMode={true} />}
              />
              <Route
                path={`${RoutesPath.VIEW_EARLY_ACCESS}/:dealId/:companyId${RoutesPath.VIEW_OVERALL_SUBSCRIPTION_STATUS}`}
                element={<OverallSubscriptionStatus />}
              />
              <Route
                path={`${RoutesPath.VIEW_WISHLIST}/:dealId/:companyId${RoutesPath.VIEW_OVERALL_WISHLISTED_USERS}`}
                element={<OverallWishlistedUsers />}
              />
              <Route
                path={`${RoutesPath.TRANSACTIONS}`}
                element={<TransactionList />}
              />
            </Routes>
          </Suspense>
        </div>
      </div>
    </div>
  );
};

export default PrivateLayout;
